/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { BenefitDefinitionCreate } from "../models/BenefitDefinitionCreate";
import type { BenefitDefinitionRead } from "../models/BenefitDefinitionRead";
import type { BenefitDefinitionReadWithOrganisationSummary } from "../models/BenefitDefinitionReadWithOrganisationSummary";
import type { BenefitDefinitionTermsRead } from "../models/BenefitDefinitionTermsRead";
import type { BenefitDefinitionUpdate } from "../models/BenefitDefinitionUpdate";
import type { BenefitDefinitionUserRead } from "../models/BenefitDefinitionUserRead";
import type { Body_benefit_definitions_create_benefit_definition_terms } from "../models/Body_benefit_definitions_create_benefit_definition_terms";
import type { Body_benefit_definitions_duplicate_benefit_definition } from "../models/Body_benefit_definitions_duplicate_benefit_definition";
import type { Body_benefit_definitions_process_benefit_definition_merger } from "../models/Body_benefit_definitions_process_benefit_definition_merger";
import type { Body_benefit_definitions_update_benefit_definition_terms } from "../models/Body_benefit_definitions_update_benefit_definition_terms";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedBenefitDefinitionCandidateOut } from "../models/PaginatedBenefitDefinitionCandidateOut";
import type { PaginatedBenefitDefinitionTermsOut } from "../models/PaginatedBenefitDefinitionTermsOut";
import type { PaginatedUserBenefitActivationOut } from "../models/PaginatedUserBenefitActivationOut";
import type { SupportedOrganisationCountries } from "../models/SupportedOrganisationCountries";
import type { UserBenefitActivationRead } from "../models/UserBenefitActivationRead";

export class BenefitDefinitionsService {
  /**
   * Get Benefit Definitions Activations
   * @param activeOnly
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedUserBenefitActivationOut Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinitionsActivations(
    activeOnly: boolean = false,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedUserBenefitActivationOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/benefit-definitions/activations",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        active_only: activeOnly,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Benefit Definitions Candidates
   * @returns PaginatedBenefitDefinitionCandidateOut Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinitionsCandidates(): CancelablePromise<PaginatedBenefitDefinitionCandidateOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/benefit-definitions/candidates",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Benefit Definition
   * @param benefitId
   * @param onlyActive
   * @returns BenefitDefinitionUserRead Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinition(
    benefitId: string,
    onlyActive: boolean = true,
  ): CancelablePromise<BenefitDefinitionUserRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/benefit-definitions/{benefit_id}",
      path: {
        benefit_id: benefitId,
      },
      query: {
        only_active: onlyActive,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Benefit Definition Current Terms
   * @param benefitId
   * @returns BenefitDefinitionTermsRead Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinitionCurrentTerms(
    benefitId: string,
  ): CancelablePromise<BenefitDefinitionTermsRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/benefit-definitions/{benefit_id}/current-terms",
      path: {
        benefit_id: benefitId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Activate Benefit Definition
   * @param benefitId
   * @returns UserBenefitActivationRead Successful Response
   * @throws ApiError
   */
  public static activateBenefitDefinition(
    benefitId: string,
  ): CancelablePromise<UserBenefitActivationRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/benefit-definitions/{benefit_id}/activate",
      path: {
        benefit_id: benefitId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search Benefit Definition
   * @param organisationId
   * @param benefitAccessRulesUuid
   * @param availableForUserId
   * @param onlyActive
   * @param country
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns any Successful Response
   * @throws ApiError
   */
  public static searchBenefitDefinition(
    organisationId?: string,
    benefitAccessRulesUuid?: string,
    availableForUserId?: string,
    onlyActive: boolean = false,
    country?: SupportedOrganisationCountries,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/benefit-definitions",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        organisation_id: organisationId,
        benefit_access_rules_uuid: benefitAccessRulesUuid,
        available_for_user_id: availableForUserId,
        only_active: onlyActive,
        country: country,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Benefit Definition
   * @param requestBody
   * @returns BenefitDefinitionRead Successful Response
   * @throws ApiError
   */
  public static createBenefitDefinition(
    requestBody: BenefitDefinitionCreate,
  ): CancelablePromise<BenefitDefinitionRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/benefit-definitions",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Benefit Definition
   * @param benefitId
   * @returns BenefitDefinitionReadWithOrganisationSummary Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinition1(
    benefitId: string,
  ): CancelablePromise<BenefitDefinitionReadWithOrganisationSummary> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/benefit-definitions/{benefit_id}",
      path: {
        benefit_id: benefitId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Benefit Definition
   * @param benefitId
   * @param requestBody
   * @returns BenefitDefinitionRead Successful Response
   * @throws ApiError
   */
  public static updateBenefitDefinition(
    benefitId: string,
    requestBody: BenefitDefinitionUpdate,
  ): CancelablePromise<BenefitDefinitionRead> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/admin-api/benefit-definitions/{benefit_id}",
      path: {
        benefit_id: benefitId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Process Benefit Definition Merger
   * @param benefitId
   * @param requestBody
   * @returns BenefitDefinitionRead Successful Response
   * @throws ApiError
   */
  public static processBenefitDefinitionMerger(
    benefitId: string,
    requestBody: Body_benefit_definitions_process_benefit_definition_merger,
  ): CancelablePromise<BenefitDefinitionRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/benefit-definitions/{benefit_id}/process-merger",
      path: {
        benefit_id: benefitId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Duplicate Benefit Definition
   * @param benefitId
   * @param requestBody
   * @returns BenefitDefinitionRead Successful Response
   * @throws ApiError
   */
  public static duplicateBenefitDefinition(
    benefitId: string,
    requestBody: Body_benefit_definitions_duplicate_benefit_definition,
  ): CancelablePromise<BenefitDefinitionRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/benefit-definitions/{benefit_id}/duplicate",
      path: {
        benefit_id: benefitId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search Benefit Definition Terms
   * @param benefitDefinitionId
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedBenefitDefinitionTermsOut Successful Response
   * @throws ApiError
   */
  public static searchBenefitDefinitionTerms(
    benefitDefinitionId?: string,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedBenefitDefinitionTermsOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/benefit-definition-terms",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        benefit_definition_id: benefitDefinitionId,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Benefit Definition Terms
   * @param formData
   * @returns BenefitDefinitionTermsRead Successful Response
   * @throws ApiError
   */
  public static createBenefitDefinitionTerms(
    formData: Body_benefit_definitions_create_benefit_definition_terms,
  ): CancelablePromise<BenefitDefinitionTermsRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/benefit-definition-terms",
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Benefit Definition Terms
   * @param benefitDefinitionTermsId
   * @returns BenefitDefinitionTermsRead Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinitionTerms(
    benefitDefinitionTermsId: string,
  ): CancelablePromise<BenefitDefinitionTermsRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/benefit-definition-terms/{benefit_definition_terms_id}",
      path: {
        benefit_definition_terms_id: benefitDefinitionTermsId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Benefit Definition Terms
   * @param benefitDefinitionTermsId
   * @param formData
   * @returns BenefitDefinitionTermsRead Successful Response
   * @throws ApiError
   */
  public static updateBenefitDefinitionTerms(
    benefitDefinitionTermsId: string,
    formData?: Body_benefit_definitions_update_benefit_definition_terms,
  ): CancelablePromise<BenefitDefinitionTermsRead> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/admin-api/benefit-definition-terms/{benefit_definition_terms_id}",
      path: {
        benefit_definition_terms_id: benefitDefinitionTermsId,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Activate Benefit Definition Terms
   * @param benefitDefinitionTermsId
   * @returns BenefitDefinitionTermsRead Successful Response
   * @throws ApiError
   */
  public static activateBenefitDefinitionTerms(
    benefitDefinitionTermsId: string,
  ): CancelablePromise<BenefitDefinitionTermsRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/benefit-definition-terms/{benefit_definition_terms_id}/activate",
      path: {
        benefit_definition_terms_id: benefitDefinitionTermsId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
