/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Body_user_merge_users } from "../models/Body_user_merge_users";
import type { CurrentUserRead } from "../models/CurrentUserRead";
import type { MasterPermissions } from "../models/MasterPermissions";
import type { Msg } from "../models/Msg";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedUserAdminOut } from "../models/PaginatedUserAdminOut";
import type { PaginatedUserEmailOut } from "../models/PaginatedUserEmailOut";
import type { PasswordChange } from "../models/PasswordChange";
import type { SupportedCountriesEnum } from "../models/SupportedCountriesEnum";
import type { Token } from "../models/Token";
import type { UserAdminCreate } from "../models/UserAdminCreate";
import type { UserAdminRead } from "../models/UserAdminRead";
import type { UserEmailPrivateCreate } from "../models/UserEmailPrivateCreate";
import type { UserEmailRead } from "../models/UserEmailRead";
import type { UserRead } from "../models/UserRead";
import type { UserTerms } from "../models/UserTerms";
import type { UserUpdate } from "../models/UserUpdate";
import type { VerificationCode } from "../models/VerificationCode";
import type { VerificationToken } from "../models/VerificationToken";

export class UserService {
  /**
   * Read Current User
   * @returns CurrentUserRead Successful Response
   * @throws ApiError
   */
  public static readCurrentUser(): CancelablePromise<CurrentUserRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/users/me",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Current User
   * @param requestBody
   * @returns UserRead Successful Response
   * @throws ApiError
   */
  public static updateCurrentUser(
    requestBody: UserUpdate,
  ): CancelablePromise<UserRead> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/users/me",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Send Phone Verification Code
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static sendPhoneVerificationCode(): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/me/send-phone-verification-code",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Verify Phone Number
   * @param requestBody
   * @returns UserRead Successful Response
   * @throws ApiError
   */
  public static verifyPhoneNumber(
    requestBody: VerificationCode,
  ): CancelablePromise<UserRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/me/verify-phone-number",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Change Password
   * @param requestBody
   * @returns Token Successful Response
   * @throws ApiError
   */
  public static changePassword(
    requestBody: PasswordChange,
  ): CancelablePromise<Token> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/users/me/change-password",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search User Emails
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedUserEmailOut Successful Response
   * @throws ApiError
   */
  public static searchUserEmails(
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedUserEmailOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/users/me/emails",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create User Email
   * @param requestBody
   * @param resumePath
   * @returns UserEmailRead Successful Response
   * @throws ApiError
   */
  public static createUserEmail(
    requestBody: UserEmailPrivateCreate,
    resumePath?: string,
  ): CancelablePromise<UserEmailRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/me/emails",
      query: {
        resume_path: resumePath,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get User Email
   * @param userEmailId
   * @returns UserEmailRead Successful Response
   * @throws ApiError
   */
  public static getUserEmail(
    userEmailId: string,
  ): CancelablePromise<UserEmailRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/users/me/emails/{user_email_id}",
      path: {
        user_email_id: userEmailId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete User Email
   * @param userEmailId
   * @returns void
   * @throws ApiError
   */
  public static deleteUserEmail(userEmailId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/users/me/emails/{user_email_id}",
      path: {
        user_email_id: userEmailId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Make User Email Primary
   * @param userEmailId
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static makeUserEmailPrimary(
    userEmailId: string,
  ): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/me/emails/{user_email_id}/make-primary",
      path: {
        user_email_id: userEmailId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Send Email Verification Token
   * @param userEmailId
   * @param resumePath
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static sendEmailVerificationToken(
    userEmailId: string,
    resumePath?: string,
  ): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/me/emails/{user_email_id}/send-email-verification-token",
      path: {
        user_email_id: userEmailId,
      },
      query: {
        resume_path: resumePath,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get User Terms
   * @param userTermsId
   * @returns UserTerms Successful Response
   * @throws ApiError
   */
  public static getUserTerms(
    userTermsId: string,
  ): CancelablePromise<UserTerms> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/user-terms/{user_terms_id}",
      path: {
        user_terms_id: userTermsId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Download User Terms
   * @param userTermsId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static downloadUserTerms(userTermsId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/user-terms/{user_terms_id}/download",
      path: {
        user_terms_id: userTermsId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Accept User Terms
   * @param userTermsId
   * @returns void
   * @throws ApiError
   */
  public static acceptUserTerms(userTermsId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/user-terms/{user_terms_id}/accept",
      path: {
        user_terms_id: userTermsId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Verify Email
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static verifyEmail(
    requestBody: VerificationToken,
  ): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/users/me/verify-email",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search Users
   * @param shopId
   * @param benefitDefinitionId
   * @param masterPermission
   * @param country
   * @param includeDeleted
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedUserAdminOut Successful Response
   * @throws ApiError
   */
  public static searchUsers(
    shopId?: string,
    benefitDefinitionId?: string,
    masterPermission?: MasterPermissions,
    country?: SupportedCountriesEnum,
    includeDeleted: boolean = false,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedUserAdminOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/users",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        shop_id: shopId,
        benefit_definition_id: benefitDefinitionId,
        master_permission: masterPermission,
        country: country,
        include_deleted: includeDeleted,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create User
   * @param requestBody
   * @returns UserAdminRead Successful Response
   * @throws ApiError
   */
  public static createUser(
    requestBody: UserAdminCreate,
  ): CancelablePromise<UserAdminRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/users",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Send Invitation Email
   * @param userId
   * @returns void
   * @throws ApiError
   */
  public static sendInvitationEmail(userId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/users/{user_id}/send-invitation-email",
      path: {
        user_id: userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get User
   * @param userId
   * @param validateForOrdering
   * @returns UserAdminRead Successful Response
   * @throws ApiError
   */
  public static getUser(
    userId: string,
    validateForOrdering: boolean = false,
  ): CancelablePromise<UserAdminRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/users/{user_id}",
      path: {
        user_id: userId,
      },
      query: {
        validate_for_ordering: validateForOrdering,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update User
   * @param userId
   * @param requestBody
   * @returns UserAdminRead Successful Response
   * @throws ApiError
   */
  public static updateUser(
    userId: string,
    requestBody: UserUpdate,
  ): CancelablePromise<UserAdminRead> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/admin-api/users/{user_id}",
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Soft Delete User
   * @param userId
   * @returns void
   * @throws ApiError
   */
  public static softDeleteUser(userId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/admin-api/users/{user_id}",
      path: {
        user_id: userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Merge Users
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static mergeUsers(
    requestBody: Body_user_merge_users,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin-api/users/merge",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
